import landing from "@/components/sharedComponents/landing/landing.component";
import { LandingItem } from "@/interfaces/landingItem";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import Template from "./LocationMenu.template.vue";

@Component({
  mixins: [Template],
  components: {
    landing
  }
})
export default class LocationMenuComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;

  public landingOptions: LandingItem[] = [
    {
      namedRoute: "sales-limit",
      translationString: "sales_limit"
    },
    {
      namedRoute: "template-manager",
      translationString: "templates.manager"
    },
    {
      namedRoute: "import-export-wizard",
      translationString: "importModule.wizard_title"
    }
  ];
  protected goTo(namedRoute: string): void {
    this.$router.push({ name: namedRoute });
  }

  protected mounted() {
    this.setPageNav({
      title: ""
    });
  }
}
